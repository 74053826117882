import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Cookies from "js-cookie";

import "assets/stylesheets/style.scss";
import Meta from "./common/Meta";

const AgeGate = () => {
  const CONSENT_COOKIE_NAME = "_ths_agegate";

  const [overAge, setOverAge] = useState(
    Cookies.get(CONSENT_COOKIE_NAME) === "true",
  );

  const handleOver = () => {
    Cookies.set(CONSENT_COOKIE_NAME, "true");
    setOverAge(true);
  };

  const handleUnder = () => {
    window.location = "https://google.com";
  };

  return (
    <Modal show={!overAge} centered backdropClassName="fade-thick">
      <Modal.Body className="text-center">
        <h1 className="mb-6">Are you Over 21?</h1>
        <div>
          <Button
            variant="primary"
            size="lg"
            className="mr-2"
            onClick={handleOver}
          >
            Yes
          </Button>
          <Button variant="outline-secondary" size="lg" onClick={handleUnder}>
            No
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const Layout = ({ children }) => {
  return (
    <>
      <AgeGate />
      <Meta />
      {children}
    </>
  );
};

Layout.defaultProps = {};

export default Layout;
