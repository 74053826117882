import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import URL from "url";

const Head = ({
  siteTitle,
  title,
  description,
  lang = "en-US",
  siteUrl,
  image,
  twitterUsername,
  twitterAuthor,
  location,
  pageType = "website",
  twitterCard = "summary",
  bodyClass,
  children,
}) => {
  const canonicalUrl = location
    ? URL.resolve(siteUrl, location.pathname)
    : siteUrl;

  const imageUrl = image ? URL.resolve(siteUrl, image) : null;

  return (
    <Helmet
      defaultTitle={siteTitle}
      titleTemplate={`%s | ${siteTitle}`}
      title={title}
    >
      <html lang={lang} />
      <meta name="description" content={description} />

      <meta property="og:title" content={title || siteTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:type" content={pageType} />
      <meta property="og:site_name" content={siteTitle} />
      {imageUrl && <meta property="og:image" content={imageUrl} />}

      <meta name="twitter:card" content={twitterCard} />
      {twitterUsername && (
        <meta name="twitter:site" content={`@${twitterUsername}`} />
      )}
      {twitterAuthor && (
        <meta name="twitter:creator" content={`@${twitterUsername}`} />
      )}

      <link rel="canonical" href={canonicalUrl} />
      <body className={bodyClass} />
      {children}
    </Helmet>
  );
};

const Meta = props => {
  const {
    site: { siteMetadata },
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteTitle
          description
          siteUrl
          twitterUsername
        }
      }
      file(relativePath: { eq: "logo-crest-1024.png" }) {
        childImageSharp {
          fluid(maxHeight: 630) {
            src
          }
        }
      }
    }
  `);
  return <Head {...siteMetadata} image={fluid.src} {...props} />;
};
export default Meta;
